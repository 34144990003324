<template>
  <div class="fill-pic-group-setting">
    <div class="main">
      <!-- 基础设置 -->
      <div class="setting-box">
        <div class="setting-item">
          <span class="title">上下边距</span>
          <el-slider
            v-model="dataInfo.MarginTopBottom"
            :show-tooltip="false"
            :max="50"
          ></el-slider>
          <span class="value">{{ dataInfo.MarginTopBottom }}px</span>
        </div>

        <div class="setting-item">
          <span class="title">左右边距</span>
          <el-slider
            v-model="dataInfo.MarginLeftRight"
            :show-tooltip="false"
            :max="50"
          ></el-slider>
          <span class="value">{{ dataInfo.MarginLeftRight }}px</span>
        </div>

        <div class="setting-item">
          <span class="title">背景颜色</span>
          <el-color-picker
            v-model="dataInfo.BackColor"
            show-alpha
            size="mini"
            @change="onReset('BackColor', $event)"
          >
          </el-color-picker>
        </div>
      </div>

      <!-- 图片、链接设置 -->
      <div class="image-box">
        <div
          class="image-item"
          v-for="(item, index) in dataInfo.PicItems"
          :key="index"
        >
          <div class="image">
            <span class="title">图片</span>
            <el-image
              :src="item.PicUrl || require('@/assets/img/no-pictrue-rectangle.png')"
              class="avatar"
              @click="onAddPictrue(index)"
            ></el-image>
          </div>

          <div class="url">
            <span class="title">链接地址</span>
            <el-input v-model="item.LinkPath" size="mini"></el-input>
          </div>

          <span
            class="remove-btn dfWebIcon dfWebIcon-qingchu"
            @click="onRemove(index)"
          ></span>
        </div>
      </div>
    </div>

    <div class="footer">
      <el-button size="mini" @click="onAddListChild"
        >添加一个</el-button
      >
    </div>

    <DFGalleryPictrue ref="DFGalleryPictrue" @onComfirm="onSaveMedia"></DFGalleryPictrue>
  </div>
</template>

<script>

export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => ({
        Code: "FillPicGroup",
        Name: "单图组",
        MarginTopBottom: 0,
        MarginLeftRight: 0,
        BackColor: "rgba(255,255,255,1)",
        PicItems: [
          {
            PicUrl: "",
            LinkPath: "",
          },
          {
            PicUrl: "",
            LinkPath: "",
          },
        ],
      }),
    },
  },

  data() {
    return {
      selectImageIndex: -1,
      // defaultPicUrl:
      //   "@/assets/img/no-pictrue-rectangle.png",
    };
  },

  methods: {
    // 选择图片
    onAddPictrue(index) {
      this.selectImageIndex = index;
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 保存图片
    onSaveMedia(event) {
      // let dataInfo =  JSON.parse(JSON.stringify(this.dataInfo))
      this.dataInfo.PicItems[this.selectImageIndex].PicUrl = event[0].MediaUrl;
      // console.log(dataInfo.PicItems);
      // console.log(this.dataInfo);
      // this.dataInfo = dataInfo
    },

    // 添加一个图片列表对象
    onAddListChild() {
      this.dataInfo.PicItems.push({
        PicUrl: "",
        LinkPath: "",
      });
    },

    // 重置初始值
    onReset(keyName, value) {
      //   console.log(keyName, value);
      if (keyName == "BackColor" && !value) {
        value = "rgba(255,255,255,1)";
      }
      this.dataInfo[keyName] = value;
    },

    // 移除图片
    onRemove(index) {
      if (this.dataInfo.PicItems.length == 1) {
        return this.$message.info("至少保留一个");
      }
      this.dataInfo.PicItems.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.fill-pic-group-setting {
  height: 100%;

  .main {
    .flex-col();
    width: 100%;
    max-height: calc(100% - 30px);
    overflow-y: auto;

    .page-setting-box();

    .page-image-box();
  }

  .footer {
    width: 100%;

    .el-button{
        margin-top: 10px;
        width: 100%;
    }
  }
}
</style>
