<template>
  <div class="template-setting">
    <div class="top">
      <div class="bule"></div>
      <span class="title">{{ selectedTempInfo.Name || "页面设置" }}</span>
    </div>

    <div class="list-box">
      <template v-if="templateIndex == -1">
        <NavigateBarSeeting :dataInfo="selectedTempInfo"></NavigateBarSeeting>
      </template>

      <template v-else-if="selectedTempInfo.Code == 'FillPicGroup'">
        <FillPictrueGroupSetting
          :dataInfo="selectedTempInfo"
        ></FillPictrueGroupSetting>
      </template>

      <template v-else-if="selectedTempInfo.Code == 'IconNaviGroup'">
        <IconNaviGroupSetting
          :dataInfo="selectedTempInfo"
        ></IconNaviGroupSetting>
      </template>

      <template v-else-if="selectedTempInfo.Code == 'TitleNaviGroup'">
        <TitleNaviGroupSetting
          :dataInfo="selectedTempInfo"
        ></TitleNaviGroupSetting>
      </template>
    </div>
  </div>
</template>

<script>
import FillPictrueGroupSetting from "./fill-pictrue-group/fiil-pictrue-group-setting.vue";
import NavigateBarSeeting from "./navigate-bar-setting.vue";
import IconNaviGroupSetting from "./icon-navi-group/icon-navi-group-setting.vue";
import TitleNaviGroupSetting from "./title-navi-group/title-navi-group-setting.vue";

export default {
  components: {
    FillPictrueGroupSetting,
    NavigateBarSeeting,
    IconNaviGroupSetting,
    TitleNaviGroupSetting
  },

  props: {
    selectedTempInfo: {
      type: Object,
      default: () => ({}),
    },

    templateIndex: {
      type: Number,
      default: () => -2,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.template-setting {
  .flex-col();
  align-items: unset;
  width: 60%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;

  > div {
    padding: 10px 0;
  }

  .top {
    .flex-row();
    border-bottom: 1px solid #ddd;

    .bule {
      height: 70%;
      width: 4px;
      margin-right: 8px;
      background: #20a0ff;
    }
  }

  .list-box {
    flex: 1;
    //   max-height: calc(var(--maxHeight) - 129px);
    max-height: unset !important;
    overflow-y: auto;

    .title {
      font-size: 12px;
      color: #999;
    }

    .component-list {
      .flex-row();
      flex-wrap: wrap;
      width: 100%;
      box-sizing: border-box;

      .component-item {
        .flex-col();
        justify-content: center;
        width: 75px;
        height: 55px;
        padding: 5px 0;
        margin: 10px;
        background-color: #f1f1f1;
        border: 1px solid #ddd;
        box-sizing: border-box;
        transition: all 0.3s ease-in-out;

        span {
          .flex-col();
          justify-content: center;
          flex: 1;
          font-size: 12px;
        }

        .df-icon {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .component-item:hover {
        background-color: white;
        border-color: #20a0ff;
        cursor: pointer;
      }
    }
  }

  .footer {
    text-align: right;
    border-top: 1px solid #ddd;
  }
}

.scrollbar();

.hidden {
  display: none;
}
</style>
