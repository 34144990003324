<template>
  <div class="home-icon-navigation-setting">
    <div class="main">
      <!-- 基础设置 -->
      <div class="setting-box">
        <div class="setting-item">
          <span class="title">每行显示几个</span>
          <el-input
            type="number"
            v-model="dataInfo.PerLineCount"
            :min="1"
            size="mini"
          >
          </el-input>
          <span class="tips">(图标太多需要设置此处，使小程序分行显示)</span>
        </div>

        <div class="setting-item">
          <span class="title">背景颜色</span>
          <el-color-picker
            v-model="dataInfo.BackColor"
            show-alpha
            size="mini"
            @change="onReset('BackColor', $event)"
          >
          </el-color-picker>
        </div>
      </div>

      <!-- 图片、链接设置 -->
      <div class="image-box">
        <span class="tips">※ 图片尺寸建议30像素×30像素 大小不超过1MB</span>
        <div
          class="image-item"
          v-for="(item, index) in dataInfo.NaviItems"
          :key="index"
        >
          <div class="image">
            <span class="title">图片</span>
            <el-image
              class="avatar"
              :src="
                item.IconUrl || require('@/assets/img/no-pictrue-rectangle.png')
              "
              @click="onAddPictrue(index)"
            ></el-image>
          </div>

          <div class="text">
            <span class="title">文字</span>
            <el-input v-model="item.Text" size="mini"></el-input>
          </div>

          <div class="url">
            <span class="title">链接地址</span>
            <el-input v-model="item.LinkPath" size="mini"></el-input>
          </div>

          <span
            class="remove-btn dfWebIcon dfWebIcon-qingchu"
            @click="onRemove(index)"
          ></span>
        </div>
      </div>
    </div>

    <div class="footer">
      <el-button size="mini" @click="onAddListChild">添加一个</el-button>
    </div>

    <DFGalleryPictrue
      ref="DFGalleryPictrue"
      @onComfirm="onSaveMedia"
    ></DFGalleryPictrue>
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => ({
        BackColor: "rgba(255,255,255,1)",
        Code: "IconNaviGroup",
        Name: "图标导航组",
        NaviItems: [{ IconUrl: "", Text: "", LinkPath: "" }],
        PerLineCount: 0,
      }),
    },
  },

  watch: {
    dataInfo: {
      handler(newVal) {
        if (!newVal.BackColor) {
          newVal.BackColor = "rgba(255,255,255,1)";
        }
        if (!newVal.NaviItems.length) {
          this.onAddListChild();
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      selectImageIndex: -1,
    };
  },

  methods: {
    onAddPictrue(index) {
      this.selectImageIndex = index;
      this.$refs.DFGalleryPictrue.onShowDialog();
    },

    // 保存图片
    onSaveMedia(event) {
      this.dataInfo.NaviItems[this.selectImageIndex].IconUrl =
        event[0].MediaUrl;
    },

    // 添加一个图片列表对象
    onAddListChild() {
      this.dataInfo.NaviItems.push({
        IconUrl: "",
        Text: "",
        LinkPath: "",
      });
    },

    // 重置初始值
    onReset(keyName, value) {
      //   console.log(keyName, value);
      if (keyName == "BackColor" && !value) {
        value = "rgba(255,255,255,1)";
      }
      this.dataInfo[keyName] = value;
    },

    // 移除图片
    onRemove(index) {
      if (this.dataInfo.NaviItems.length == 1) {
        return this.$message.info("至少保留一个");
      }
      this.dataInfo.NaviItems.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.home-icon-navigation-setting {
  height: 100%;

  .main {
    .flex-col();
    width: 100%;
    max-height: calc(100% - 30px);
    overflow-y: auto;

    .page-setting-box();

    .page-image-box();

    .setting-item {
      .title {
        width: 100px !important;
      }

      .el-input {
        width: 100px !important;
      }

      .tips {
        .flex-row();
        color: tomato;
        font-size: 10px !important;
      }
    }
  }

  .footer {
    width: 100%;

    .el-button {
      margin-top: 10px;
      width: 100%;
    }
  }

  .scrollbar();
}
</style>
