<template>
  <div class="navigate-bar-setting">
    <!-- 基础设置 -->
    <div class="setting-box">
      <div class="setting-item">
        <span class="title">页面名称</span>
        <div>
          <el-input v-model="dataInfo.PageName" size="mini"></el-input>
          <span>页面名称仅用于后台查找</span>
        </div>
      </div>

      <div class="setting-item">
        <span class="title">页面标题</span>
        <div>
          <el-input v-model="dataInfo.PageTitle" size="mini"></el-input>
          <span>小程序端顶部显示的标题</span>
        </div>
      </div>

      <div class="setting-item">
        <span class="title">分享标题</span>
        <div>
          <el-input v-model="dataInfo.ShareTitle" size="mini"></el-input>
          <span>小程序端转发时显示的标题</span>
        </div>
      </div>

      <div class="setting-item">
        <span class="title">标题栏文字</span>
        <el-radio-group v-model="dataInfo.TitleForeColor">
          <el-radio :label="'#000000'">黑色</el-radio>
          <el-radio :label="'#ffffff'">白色</el-radio>
        </el-radio-group>
      </div>

      <div class="setting-item">
        <span class="title">背景颜色</span>
        <el-color-picker
          v-model="dataInfo.TitleBackColor"
          size="mini"
          @change="onReset('TitleBackColor', $event)"
        >
        </el-color-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => ({
        PageName: "测试页",
        PageTitle: "Test",
        ShareTitle: "Hello Test",
        TitleBackColor: "#ffffff",
        TitleForeColor: "#000000",
      }),
    },
  },

  data() {
    return {
      selectImageIndex: -1,
    };
  },

  methods: {
    onAddPictrue(index) {
      this.selectImageIndex = index;
      this.$refs.GalleryDialog.onShowDialog();
    },

    // 重置初始值
    onReset(keyName, value) {
      //   console.log(keyName, value);
      if (keyName == "BackColor" && !value) {
        value = "rgba(255,255,255,1)";
      }
      this.dataInfo[keyName] = value;
    },

    // 移除图片
    onRemove(index) {
      if (this.dataInfo.PicItems.length == 1) {
        return this.$message.info("至少保留一个");
      }
      this.dataInfo.PicItems.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.navigate-bar-setting {
  .flex-col();
  width: 100%;

  .page-setting-box();

  .page-image-box();

  .setting-box .setting-item > div {
    .flex-col();
    align-items: unset;
    span {
      color: #999;
    }
  }
}
</style>
